<template>
  <div class="rightLink">
    <div class="bottomDiv">
      <div class="title">用户充值记录</div>
      <div class="formDiv">
        <el-form label-position="left" label-width="60px">
          <el-row :gutter="20">
            <!-- <el-col :span="3"></el-col> -->
            <el-col :span="7">
              <el-form-item label="编号:">
                <el-input
                  v-model="FormData.user_name"
                  placeholder="请输入编号"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="7">
              <el-form-item label="用户名:">
                <el-input
                  v-model="FormData.serial_number"
                  placeholder="请输入用户名"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="7">
              <el-form-item label="手机号:">
                <el-input
                  v-model="FormData.dutuies"
                  placeholder="请输入手机号"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="1">
              <div class="BtnSearch" @click="searchBtn()">查询</div>
            </el-col>
            
          </el-row>
        </el-form>
      </div>
      <!-- 表格部分 -->
      <div class="tableDiv">
      <el-table
        ref="multipleTableRef"
        :data="tableData"
        border
        v-loading="loading"
        style="
          width: 90%;
          margin: 20px auto;
          height: 379px;
          overflow-y: auto;
        "
      >
          <!-- <el-table-column type="selection" /> -->
          <el-table-column label="编号" prop="serial_number" />
          <el-table-column label="用户名" prop="user_name" />
          <el-table-column label="昵称" prop="user_name" />
          <el-table-column label="手机号" prop="contact_way" />
          <el-table-column label="类型" prop="wx_id" />
          <el-table-column label="真实余额" prop="mailbox" />
          <el-table-column label="虚拟余额" prop="dutuies" />
          <el-table-column label="可用总余额" prop="responsibilities" />
          <el-table-column label="充值时间" prop="user_status" />
          <el-table-column label="充值金额" prop="entry_date" />
          <el-table-column label="操作" prop="entry_date" />
        </el-table>
        <div class="pagination">
        <el-pagination
            :current-page="currentPage"
            :page-size="pageSize"
            :small="small"
            layout="total, prev, pager, next, jumper"
            :total="total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          />
      </div>
      </div>
    </div>
  </div>
</template>
<script>
// import { onMounted } from "vue";
import { onMounted, reactive, toRefs } from "vue-demi";
// import { personnel_sel } from "@/request/api";
// import { ElMessage } from "element-plus";
export default {
  name: "UserRecord",
  setup() {
    const data = reactive({
      //表单数据
      FormData: {
        // order_id: "", //营业所名称
        // order_date: [], //营业所编号
      },
      tableData: [],
      
      loading: false,
      //   分页
      currentPage:1,
      pageSize:20,
      total:400,
      checked:0,
      small:true,
    });
    const searchBtn = () => {
      getList();
    };
  
    const getList = () => {
      console.log("用户查询");
      // const dataa = data.FormData
      // console.log(dataa,'data.FormData11');
   
      // data.loading = true;
      // personnel_sel(dataa).then((res) => {
      //   console.log(res);
      //   if (res) {
      //     data.loading = false;
      //     if (res.code == 200) {
      //       data.tableData = res.data;
      //       data.total = res.data.length;
      //     } else {
      //       ElMessage.error(res.msg);
      //     }
      //   } else {
      //     ElMessage.error("数据获取失败");
      //   }
      // });
    };
    const handleSizeChange = (val)=>{
      data.pageSize = val
    }
    const handleCurrentChange = (val)=>{
        data.currentPage = val;
        getList();
        // 
    }
    onMounted(() => {
      console.log(data);
      getList();
    });
    return {
      ...toRefs(data),
      searchBtn,
      getList,
      handleSizeChange,
      handleCurrentChange
    };
  },
};
</script>

<style>
.pagination{
    display: flex;
    justify-content: space-between;
    padding: 0 67px;
    margin-top: -10px;
}
</style>